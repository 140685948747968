import React, { ReactNode } from "react"
import styled from "styled-components"

interface IProps {
  children: ReactNode
  className?: string
}

const ContentWrapper = ({ children, className }: IProps) => (
  <Container className={className}>{children}</Container>
)

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
`

export default ContentWrapper
